import React, {
  createContext,
  FC,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { usePageVisibility } from 'react-page-visibility'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import ShareIcon from '@mui/icons-material/Share'
import CheckIcon from '@mui/icons-material/Check'
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious'
import SkipNextIcon from '@mui/icons-material/SkipNext'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'
import slugify from 'react-slugify'
import parseUrl from 'url-parse'
import ReactPlayer from 'react-player'
import {
  primaryInput,
} from 'detect-it'
import MediaSession from '@mebtte/react-media-session'
import isMobile from 'is-mobile'
import queryString from 'query-string'
import './App.css'

const rossAdamson = 'Ross Adamson'
const isMobileDevice = isMobile()
//const isMobileDevice: boolean = true

const parsedQuery = queryString.parse(window.location.search)
const showHidden = parsedQuery.h === null

type YouTubePlayer = {
  pauseVideo: () => void
}

type Player = {
  play: () => void
  pause: () => void
  stop: () => void
  seekTo: (seconds: number) => void
  defaultStart?: number
}

type ExampleObj = {
  id: string
  title: string
  author?: string
  composer?: string
  hidden?: boolean
  scrollTo: () => void
}

type ClipObj = {
  label?: string
  isAudio?: boolean
  player: Player
  example: ExampleObj
}

type PlaylistManagerType = {
  registerClip: (clip: ClipObj) => number
  updateClip: (id: number, clip: ClipObj) => void
  handlePlay: (id: number) => void
  handlePause: (id: number) => void
  handleEnded: (id: number) => void
  handleError: (id: number) => void
}

const PlaylistManagerContext = createContext<PlaylistManagerType>({
  registerClip: () => -1,
  updateClip: () => undefined,
  handlePlay: () => undefined,
  handlePause: () => undefined,
  handleEnded: () => undefined,
  handleError: () => undefined,
})

const ScrollViewContext = createContext<HTMLDivElement | undefined>(undefined)

const MediaSection: FC<PropsWithChildren<{
  title: string
}>> = ({
  title,
  children,
}) => (
  <>
    <h2>{title}</h2>
    {children}
  </>
)

const ClipLabel: FC<PropsWithChildren> = ({
  children,
}) => {
  return (
  <div
    style={{
      marginTop: '10px',
      marginBottom: '10px',
    }}
  >
    <b>({children})</b>
  </div>
  )
}

type SourceObj = {
  url: string
  type?: string
  disabled?: boolean
}

type Source = string | SourceObj

function toSourceObj(source: Source): SourceObj {
  if (typeof source === 'string') {
    return {
      url: source,
    }
  }
  return source
}

type ExampleProp = {
  example?: ExampleObj
}

const VideoClip: FC<{
  label?: string
  url: Source | Source[]
  isAudio?: boolean
  isYouTube?: boolean
  heightOverWidth?: number
  start?: number
  stop?: number
  noDownload?: boolean
  useDefaultSize?: boolean
} & ExampleProp
> = ({
  label,
  url,
  heightOverWidth,
  start,
  stop,
  noDownload,
  useDefaultSize,
  example,
  isAudio,
  isYouTube,
}) => {
  const { hash } = useLocation()
  const navigate = useNavigate()
  //const isVisible = usePageVisibility()
  const playlistManager = useContext(PlaylistManagerContext)
  const playlistManagerRef = useRef(playlistManager)
  playlistManagerRef.current = playlistManager
  const [playing, setPlaying] = useState(false)
  const [paused, setPaused] = useState(false)
  const [ended, setEnded] = useState(false)
  const [ending, setEnding] = useState(false)
  const [error, setError] = useState<any>()
  const [progress, setProgress] = useState(0)
  const reactPlayerRef = useRef<ReactPlayer>()

  const isYouTubeRef = useRef(isYouTube)
  isYouTubeRef.current = isYouTube

  const progressRef = useRef(progress)

  const programmaticSeekingRef = useRef(false)

  const clipIdRef = useRef(-1)
  const [clipId, setClipId] = useState(clipIdRef.current)
  if (clipId !== -1) {
    clipIdRef.current = clipId
  }

  const setPlayer = useCallback(
    (player: ReactPlayer) => {
      reactPlayerRef.current = player
      if (start !== undefined) {
        // don't do the programmatic seeking because it only gets reset correctly
        // when player is currently playing
        // programmaticSeeking.current = true
        player.seekTo(start)
      }
    },
    [start],
  )

  const playerRef = useRef<Player>({
    play: () => {
      // TODO: remove
      console.log('play()')
      const internalPlayer = reactPlayerRef.current?.getInternalPlayer()
      //console.log('internalPlayer', internalPlayer)
      let hadFocusError = true
      if (hadFocusError) {
        try {
          internalPlayer?.focus()
          hadFocusError = false
        } catch (e) {
          hadFocusError = true
        }
      }
      if (hadFocusError) {
        try {
          internalPlayer?.h.contentWindow.focus()
          hadFocusError = false
        } catch (e) {
          hadFocusError = true
        }
      }
      if (hadFocusError) {
        console.error('Could not focus player')
        // TODO: set focus to global play/pause button
        window.blur()
      }

      playlistManagerRef.current.handlePlay(clipIdRef.current)
      setPlaying(true)
    },
    pause: () => {
      setPlaying(false)
      setPaused(true)
    },
    stop: () => {
      setPlaying(false)
      const internalPlayer = reactPlayerRef.current?.getInternalPlayer()
      if (internalPlayer) {
        if (isYouTubeRef.current) {
          (internalPlayer as YouTubePlayer).pauseVideo()
        } else {
          (internalPlayer as HTMLMediaElement).pause()
        }
      }
      playerRef.current?.seekTo(playerRef.current?.defaultStart ?? 0)
      // TODO: remove
      console.log('reset programmaticSeeking')
      programmaticSeekingRef.current = false
    },
    seekTo: seconds => {
      // NOTE: this flag only gets reset if player is currently playing
      programmaticSeekingRef.current = true
      reactPlayerRef.current?.seekTo(seconds)
    },
  })
  playerRef.current.defaultStart = start

  const onPlay = useCallback(
    () => {
      // TODO: remove
      console.log('onPlay')
      if (!programmaticSeekingRef.current) {
        playlistManager.handlePlay(clipIdRef.current)
        if (!ending) {
          setPlaying(true)
          setPaused(false)
          setEnded(false)
        }
        setError(undefined)
      }
    },
    [
      ending,
      playlistManager,
    ],
  )

  /*
  useEffect(
    () => {
      if (playing && !isVisible && !isAudio && isMobileDevice) {
        setPlaying(false)
      }
    },
    [isVisible, playing, isAudio],
  )
  */

  useEffect(
    () => {
      const timeout = setTimeout(() => {
        setPlaying(false)
      }, 5000)

      return () => clearTimeout(timeout)
    },
    [progress],
  )

  useEffect(
    () => {
      if (playing && example !== undefined) {
        const newHash = `#${example.id}`
        if (newHash !== hash) {
          navigate(newHash)
        }
      }
    },
    [navigate, playing, example, hash],
  )

  // TODO: remove
  useEffect(() => {
    if (ended) {
      setPlaying(false)
    }
  }, [ended])

  const source = useMemo(
    () => {
      const sources = Array.isArray(url) ? url : [url]
      const simplified = sources.map(source => toSourceObj(source))
        .filter(source => !source.disabled)
        .map(source => source.url)

      if (simplified.length === 1) {
        return simplified[0]
      }

      return simplified
    },
    [url],
  )

  // 16:9 aspect ratio
  const defaultHeightOverWidth = 0.5625
  const actualHeightOverWidth = heightOverWidth ?? defaultHeightOverWidth

  const darkGold = 'rgb(177, 91, 14)'

  // TODO: remove
  //console.log('playing', playing)

  useEffect(
    () => {
      if (ended && !playing) {
        setTimeout(
          () => {
            // NOTE: this flag may only get reset if the actual player is currently playing
            programmaticSeekingRef.current = true
            reactPlayerRef.current?.seekTo(start ?? 0)

            if (!isYouTube) {
              setTimeout(
                () => {
                  setEnding(false)
                  playlistManager.handleEnded(clipIdRef.current)
                },
                500,
              )
            }
          },
          500,
        )
      }
    },
    [ended, playing, start, playlistManager, isYouTube],
  )

  useEffect(
    () => {
      // TODO: remove
      //console.log('clip', source)
      if (example) {
        if (clipIdRef.current === -1) {
          clipIdRef.current = playlistManager.registerClip({
            label,
            isAudio,
            example,
            player: playerRef.current,
          })
          setClipId(clipIdRef.current)
        } else {
          playlistManager.updateClip(clipIdRef.current, {
            label,
            isAudio,
            example,
            player: playerRef.current,
          })
        }
      }
    },
    [
      source,
      playlistManager,
      label,
      isAudio,
      example,
    ],
  )

  return (
    <>
      {label && (
        <ClipLabel>{label}</ClipLabel>
      )}
      <div
        style={{
          ...(playing || paused || ended || error) && {
            border: `3px ${(paused && !ended) ? 'dotted' : 'solid'} ${error ? 'red' : ((paused || ended) ? darkGold : 'gold')}`,
            borderRadius: isAudio ? '30px' : 0,
          },
          padding: (playing || paused || ended || error) ? 0 : '3px',
        }}
      >
        <div
          style={{
            ...!useDefaultSize && {
              paddingTop: `${actualHeightOverWidth * 100}%`,
              position: 'relative',
            },
            boxSizing: 'border-box',
          }}
        >
          <ReactPlayer
            url={source}
            {...!useDefaultSize && {
              width: '100%',
              height: '100%',
              style: {
                position: 'absolute',
                top: 0,
                left: 0,
              },
            }}
            onReady={setPlayer}
            onPlay={onPlay}
            onProgress={state => {
              // TODO: remove
              //console.log('onProgress')

              //programmaticSeeking.current = false

              if (stop !== undefined && state.playedSeconds >= stop) {
                setEnded(true)
                setEnding(true)
                //setPlaying(false)
              }

              setProgress(state.playedSeconds)
              progressRef.current = state.playedSeconds
            }}
            onError={error => {
              setError(error)
              setPlaying(false)
              setPaused(true)
              playlistManager.handleError(clipIdRef.current)
            }}
            onPause={() => {
              // TODO: remove
              console.log('onPause')

              console.log('reset programmaticSeeking')
              programmaticSeekingRef.current = false

              // TODO: find the "right" fix so this line dosen't have to be here
              if (ending && isYouTube) {
                setEnding(false)
                playlistManager.handleEnded(clipIdRef.current)
              }

              //if (playing) {
                setPlaying(false)
              //}
              if (ended) {
                //playerRef.current?.seekTo(start ?? 0)
              } else {
                setPaused(true)
                if (!ending) {
                  playlistManagerRef.current.handlePause(clipIdRef.current)
                }
              }
            }}
            onEnded={() => {
              // TODO: remove
              console.log('onEnded')
              setEnded(true)
              setEnding(true)
              //setPlaying(false)
              // TODO: add
              //setPlaying(false)
            }}
            onSeek={() => {
              // TODO: remove
              console.log('onSeek')
              // This stuff doesn't work because the onSeek does not
              // fire in response to a programmatic seek
              /*
              programmaticSeeking.current = false
              if (ending) {
                setEnding(false)
              }
              */
            }}
            playing={playing}
            controls
            config={{
              file: {
                attributes: {
                  ...noDownload && {
                    controlsList: 'nodownload',
                  },
                },
              },
            }}
          />
        </div>
      </div>
    </>
  )
}

const AudioClip: FC<{
  label?: string
  url: Source | Source[]
  start?: number
  noDownload?: boolean
} & ExampleProp
> = ({
  label,
  url,
  start,
  noDownload,
  example,
}) => {
  return (
    <VideoClip
      label={label}
      url={url}
      start={start}
      noDownload={noDownload}
      heightOverWidth={0.1}
      example={example}
      isAudio
    />
  )
}

const controlBarHeight = 50

const MediaExample: FC<PropsWithChildren<{
  title: string
  links?: {
    label: ReactNode
    href: string
    title?: string
  }[]
  author?: string
  composer?: string
  description?: ReactNode
  id?: string
  hidden?: boolean
}>> = ({
  title,
  links,
  author,
  composer,
  description,
  id,
  hidden,
  children,
}) => {
  const { hash } = useLocation()
  const navigate = useNavigate()
  const scrollViewElement = useContext(ScrollViewContext)
  const useHoverEffect = primaryInput === 'mouse'
  const [showCopyLink, setShowCopyLink] = useState(!useHoverEffect)
  const [didCopyLink, setDidCopyLink] = useState(false)
  const [hashSelected, setHashSelected] = useState(false)
  const [highlighted, setHighlighted] = useState(false)
  const isHoveredRef = useRef(false)

  const exampleId = useMemo(
    () => id ?? slugify(title),
    [id, title],
  )

  const exampleLink = useMemo(
    () => {
      const url = parseUrl(window.location.href)
      url.set('hash', exampleId)
      return url.toString()
    },
    [exampleId],
  )

  const [container, setContainer] = useState<HTMLDivElement | undefined>()

  const handleContainer = useCallback(
    (node: HTMLDivElement) => setContainer(node),
    [],
  )

  useLayoutEffect(() => {
    const hashId = hash.replace('#', '')
    setHashSelected(hashId === exampleId)
  }, [hash, exampleId])

  useLayoutEffect(() => {
    setHighlighted(hashSelected)
  }, [hashSelected])

  const scrollToExampleIfNeeded = useCallback(() => {
    if (container && scrollViewElement) {
      const rect = container.getBoundingClientRect()
      const scrollViewRect = scrollViewElement.getBoundingClientRect()
      const visibleHeightNeeded = 50
      if (
        (rect.top + visibleHeightNeeded) > scrollViewRect.bottom
        || (rect.bottom - visibleHeightNeeded) < 0
      ) {
        // scroll to a little above the top of the container
        const offsetY = -10
        const y = Math.max(0, scrollViewElement.scrollTop + rect.top + offsetY)
        const debug = false
        if (debug) {
          console.log(
            'y',
            y,
            'scrollTop',
            scrollViewElement.scrollTop,
            'scrollViewBottom',
            scrollViewRect.bottom,
            'rect.top',
            rect.top,
            'rect.bottom',
            rect.bottom,
          )
        }
        scrollViewElement.scrollTo({ top: y, behavior: 'smooth' })
      }
    }
  }, [container, scrollViewElement])

  useEffect(() => {
    if (container && scrollViewElement && hashSelected) {
      scrollToExampleIfNeeded()
    }
  }, [container, scrollViewElement, hashSelected, scrollToExampleIfNeeded])

  const hideLinkTimeoutRef = useRef<NodeJS.Timeout>()
  const hideCopiedTimeoutRef = useRef<NodeJS.Timeout>()

  const clipProps = useMemo<Required<ExampleProp>>(
    () => ({
      example: {
        id: exampleId,
        title,
        author,
        composer,
        hidden,
        scrollTo: scrollToExampleIfNeeded,
      },
      // TODO: add info regarding order of clip
    }),
    [
      exampleId,
      title,
      author,
      composer,
      hidden,
      scrollToExampleIfNeeded,
    ],
  )

  const childrenWithAdjustedProps = React.Children.map(children, child => {
    if (React.isValidElement(child) && (child.type === VideoClip || child.type === AudioClip)) {
      // TODO: make type safety better here?
      return React.cloneElement(
        child,
        clipProps,
      )
    }
    return child
  })

  if (hidden && !highlighted) {
    return null
  }

  return (
    <div
      ref={handleContainer}
      className="songdiv"
      style={{
        border: highlighted ? '3px solid gold' : '1px solid rgba(252, 165, 3, 0.5)',
        borderRadius: 10,
        paddingLeft: highlighted ? 10 : 12,
        paddingRight: highlighted ? 10 : 12,
        paddingTop: highlighted ? 0 : 2,
        paddingBottom: highlighted ? 0 : 2,
      }}
      {...useHoverEffect && {
        onMouseOver: () => {
          isHoveredRef.current = true
          if (hideLinkTimeoutRef.current !== undefined) {
            clearTimeout(hideLinkTimeoutRef.current)
            hideLinkTimeoutRef.current = undefined
          } else {
            setShowCopyLink(true)
          }
        },
        onMouseOut: () => {
          isHoveredRef.current = false
          if (hideLinkTimeoutRef.current !== undefined) {
            return
          }
          hideLinkTimeoutRef.current = setTimeout(() => {
            hideLinkTimeoutRef.current = undefined
            setShowCopyLink(false)
            if (hideCopiedTimeoutRef.current !== undefined) {
              hideCopiedTimeoutRef.current = undefined
              clearTimeout(hideCopiedTimeoutRef.current)
            }
            setDidCopyLink(false)
          }, didCopyLink ? 3000 : 100)
        },
      }}
    >
      <h3>
        <em>{title}</em>
        {author && author !== rossAdamson && (
          <>
            {' '}
            <span style={{ fontSize: '80%', whiteSpace: 'nowrap' }}>({author})</span>
          </>
        )}
        {links && (
          <>
            {' — '}
            {links.map((link, i) => (
              <>
                {i > 0 && ' | '}
                <a
                  title={link.title}
                  href={link.href}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  {link.label}
                </a>
              </>
            ))}
          </>
        )}
        {showCopyLink && (
          <>
            <CopyToClipboard
              text={exampleLink}
              onCopy={() => {
                const navigateOnLinkClick = false
                if (navigateOnLinkClick) {
                  const newHash = `#${exampleId}`
                  if (newHash !== hash) {
                    navigate(newHash)
                  }
                }
                if (hideCopiedTimeoutRef.current !== undefined) {
                  hideCopiedTimeoutRef.current = undefined
                  clearTimeout(hideCopiedTimeoutRef.current)
                }
                setDidCopyLink(true)
                hideCopiedTimeoutRef.current = setTimeout(() => {
                  hideCopiedTimeoutRef.current = undefined
                  setDidCopyLink(false)
                  if (useHoverEffect && !isHoveredRef.current) {
                    setShowCopyLink(false)
                  }
                }, 3000)
              }}
            >
              <IconButton
                style={{
                  maxHeight: 15,
                  paddingTop: 0,
                  paddingBottom: 0,
                  marginTop: 0,
                  marginBottom: 0,
                  color: didCopyLink ? 'coral' : 'orange',
                }}
                disabled={didCopyLink}
                data-id={`share-example-button-${exampleId}`}
              >
                {didCopyLink ? (
                  <CheckIcon style={{ fontSize: '80%' }} />
                ) : (
                  <ShareIcon style={{ fontSize: '80%' }} />
                )}
              </IconButton>
            </CopyToClipboard>
            {didCopyLink && (
              <em style={{ fontSize: '85%', color: 'coral', whiteSpace: 'nowrap' }}>
                copied to clipboard
              </em>
            )}
          </>
        )}
      </h3>
      {childrenWithAdjustedProps}
      {description && (
        <p>
          {description}
        </p>
      )}
    </div>
  )
}

type EnhancedClipObj = ClipObj & {
  id: number
  error?: any
  playing?: boolean
}

function App() {
  const isVisible = usePageVisibility()
  const isVisibleRef = useRef(isVisible)
  isVisibleRef.current = isVisible

  const invisibleClipRef = useRef<EnhancedClipObj>()

  const [clipIncrementor, setClipIncrementor] = useState(0)
  const [clipByIdMap, setClipByIdMap] = useState<Map<number, EnhancedClipObj>>(new Map())
  const [currentClipId, setCurrentClipId] = useState(-1)
  const clipIncrementorRef = useRef(clipIncrementor)
  clipIncrementorRef.current = clipIncrementor

  const currentClipIdRef = useRef(currentClipId)
  currentClipIdRef.current = currentClipId

  const clipByIdMapRef = useRef(clipByIdMap)

  const playPauseButtonRef = useRef<HTMLButtonElement | null>()
  const setPlayPauseButton = useCallback(
    (elem: HTMLButtonElement | null) => playPauseButtonRef.current = elem,
    [],
  )

  const getClipsRef = useRef(
    (includeClipId?: number, filterInErrors?: boolean) => {
      const clips = Array.from(clipByIdMapRef.current.values())
        .filter(clip => {
          return (
            clip.id === includeClipId
            || (
              !clip.example.hidden
              && (
                filterInErrors
                || !clip.error
              )
              && (
                mediaType === 'any'
                || !!clip.isAudio === (mediaType === 'audio')
              )
              && (
                clip.isAudio
                || !isMobileDevice
                || isVisibleRef.current
              )
            )
          )
        })
        .sort((a, b) => a.id - b.id)
      return clips
    }
  )

  let shouldAutoPlayNext: boolean = true
  let loopMode: 'none' | 'one' | 'example' | 'all' = 'all'
  let mediaType: 'audio' | 'video' | 'any' = 'any'

  const getNextClipRef = useRef((clipId: number, clips: EnhancedClipObj[], excludeSame?: boolean) => {
    const index = clips.findIndex(clip => clip.id === clipId)
    let nextIndex = -1
    if (index !== -1) {
      if (loopMode === 'one') {
        nextIndex = index
      } else {
        nextIndex = index + 1
        if (nextIndex >= clips.length) {
          if (loopMode === 'all') {
            nextIndex = 0
          } else {
            nextIndex = -1
          }
        }
      }
    }
    if (excludeSame && nextIndex === index) {
      nextIndex = -1
    }
    let nextClip: EnhancedClipObj | undefined
    if (nextIndex !== -1) {
      nextClip = clips[nextIndex]
    }
    return {
      index,
      nextIndex,
      nextClip,
    }
  })

  const getPreviousClipRef = useRef((clipId: number, clips: EnhancedClipObj[]) => {
    const index = clips.findIndex(clip => clip.id === clipId)
    let previousIndex = -1
    if (index !== -1) {
      if (loopMode === 'one') {
        previousIndex = index
      } else {
        previousIndex = index - 1
        if (previousIndex < 0) {
          const loopBackToEnd = true
          if (loopBackToEnd && loopMode === 'all') {
            previousIndex = clips.length - 1
          } else {
            previousIndex = -1
          }
        }
      }
    }
    let previousClip: EnhancedClipObj | undefined
    if (previousIndex !== -1) {
      previousClip = clips[previousIndex]
    }
    return {
      index,
      previousIndex,
      previousClip,
    }
  })

  const playlistManagerRef = useRef<PlaylistManagerType>({
    registerClip: clip => {
      const id = clipIncrementorRef.current
      clipIncrementorRef.current += 1
      setClipIncrementor(i => i + 1)
      setClipByIdMap(oldClipByIdMap => {
        const newClipByIdMap = new Map(oldClipByIdMap)
        newClipByIdMap.set(id, { ...clip, id })
        // TODO: remove
        //console.log('registerClip', id, clip)
        clipByIdMapRef.current = newClipByIdMap
        return newClipByIdMap
      })
      return id
    },
    updateClip: (id, clip) => {
      setClipByIdMap(oldClipByIdMap => {
        const newClipByIdMap = new Map(oldClipByIdMap)
        newClipByIdMap.set(id, { ...clip, id })
        // TODO: remove
        //console.log('updateClip', id, clip)
        clipByIdMapRef.current = newClipByIdMap
        return newClipByIdMap
      })
    },
    handlePlay: clipId => {
      if (invisibleClipRef.current?.id === clipId) {
        invisibleClipRef.current.player.stop()
        //invisibleClipRef.current = undefined
      } else {
        if (currentClipIdRef.current && currentClipIdRef.current !== clipId) {
          try {
            const currentClip = clipByIdMapRef.current.get(currentClipIdRef.current)
            currentClip?.player.stop()
          } catch (error) {
            // do nothing
          }
        }

        setCurrentClipId(clipId)
        currentClipIdRef.current = clipId
        setClipByIdMap(oldClipByIdMap => {
          const newClipByIdMap = new Map(oldClipByIdMap)
          const clip = oldClipByIdMap.get(clipId)
          if (clip) {
            newClipByIdMap.set(clipId, { ...clip, error: undefined, playing: true })
          }
          clipByIdMapRef.current = newClipByIdMap
          return newClipByIdMap
        })
      }
      // TODO: remove
      console.log('handlePlay', clipId)
    },
    handleEnded: clipId => {
      setClipByIdMap(oldClipByIdMap => {
        const newClipByIdMap = new Map(oldClipByIdMap)
        const clip = oldClipByIdMap.get(clipId)
        if (clip) {
          newClipByIdMap.set(clipId, { ...clip, playing: false })
        }
        clipByIdMapRef.current = newClipByIdMap
        return newClipByIdMap
      })

      if (clipId === currentClipIdRef.current && shouldAutoPlayNext) {
        const clips = getClipsRef.current(clipId)
        const { nextClip } = getNextClipRef.current(clipId, clips)
        if (nextClip) {
          nextClip.player.play()
          playPauseButtonRef.current?.focus()
        }
      }
    },
    handlePause: clipId => {
      setClipByIdMap(oldClipByIdMap => {
        const newClipByIdMap = new Map(oldClipByIdMap)
        const clip = oldClipByIdMap.get(clipId)
        if (clip) {
          newClipByIdMap.set(clipId, { ...clip, playing: false })
        }
        clipByIdMapRef.current = newClipByIdMap
        return newClipByIdMap
      })
    },
    handleError: clipId => {
      setClipByIdMap(oldClipByIdMap => {
        const newClipByIdMap = new Map(oldClipByIdMap)
        const clip = oldClipByIdMap.get(clipId)
        if (clip) {
          newClipByIdMap.set(clipId, { ...clip, error: 'error' })
        }
        clipByIdMapRef.current = newClipByIdMap
        return newClipByIdMap
      })
    },
  })

  const currentClip = clipByIdMap.get(currentClipId)
  const clips = getClipsRef.current(currentClip?.id)
  const nextClip = currentClip && getNextClipRef.current(currentClip.id, clips).nextClip
  const previousClip = currentClip && getPreviousClipRef.current(currentClip.id, clips).previousClip

  const [scrollViewElement, setScrollViewElement] = useState<HTMLDivElement | undefined>()

  const setScrollView = useCallback(
    (element: HTMLDivElement) => setScrollViewElement(element),
    [],
  )

  useEffect(
    () => {
      let result: (() => void) | undefined
      if (isVisible) {
        const timeoutRef = setTimeout(() => {
          invisibleClipRef.current = undefined
        }, 1500)
        result = () => clearTimeout(timeoutRef)
      } else {
        if (currentClipIdRef.current !== -1) {
          const clip = clipByIdMapRef.current.get(currentClipIdRef.current)
          if (clip) {
            if (!clip.isAudio && isMobileDevice && !isVisible) {
              clip.player.stop()
              invisibleClipRef.current = clip

              const clips = getClipsRef.current(currentClipIdRef.current)
              const nextClip = getNextClipRef.current(currentClipIdRef.current, clips, true).nextClip
              if (nextClip) {
                setCurrentClipId(nextClip.id)
                currentClipIdRef.current = nextClip.id
                if (clip.playing) {
                  try {
                    nextClip.player.play()
                    playPauseButtonRef.current?.focus()
                  } catch (e) {
                    // do nothing
                  }
                }
              } else {
                setCurrentClipId(-1)
                currentClipIdRef.current = -1
              }
            }
          }
        }
      }
      return result
    },
    [isVisible],
  )

  return (
    <PlaylistManagerContext.Provider value={playlistManagerRef.current}>
      <ScrollViewContext.Provider value={scrollViewElement}>
        <>
          <div
            ref={setScrollView}
            style={{
              width: '100dvw',
              height: `calc(100dvh - ${controlBarHeight}px)`,
              overflow: 'auto',
            }}
          >
            <div
              style={{
                width: '100dvw',
                minHeight: `calc(100dvh - ${controlBarHeight}px)`,
                backdropFilter: 'saturate(4.5) contrast(1.6) hue-rotate(325deg) brightness(1) blur(3px)',
                backgroundColor: 'rgba(91, 37, 8, .3)',
              }}
            >
              <div
                id="main_container"
                style={{
                  minHeight: `calc(100dvh - ${controlBarHeight}px)`,
                }}
              >
                <h1 className="center">{rossAdamson} — Composer</h1>
                <p className="center">
                  {rossAdamson} studied music composition at Brigham Young University,
                  double-majoring in Music and Computer Science. He is a freelance
                  film score composer, actor, musician, and software engineer.
                  For contact info and more, visit
                  {' '}
                  <a target="_blank" href="http://rossadamson.com" rel="noreferrer">rossadamson.com</a>.
                </p>
                <MediaSection
                  title="Film Scores"
                >
                  {showHidden && (
                    <MediaExample
                      title="Replay"
                      author="Devin G. Squire"
                      composer={rossAdamson}
                      description={(
                        <>
                          retro musical score for a short, horror/sci-fi film about
                          nostalgia, regret, and the chance at redemption.
                          I codirected this film with <a href="https://www.imdb.com/name/nm7135454/">Devin</a>.
                        </>
                      )}
                    >
                      <AudioClip
                        url="compositions/Replay - music 2024-01-30.m4a"
                        noDownload
                      />
                    </MediaExample>
                  )}
                  <MediaExample
                    title="The Birds"
                    author="Alfred Hitchcock"
                    composer={rossAdamson}
                    description={(
                      <>
                        What better way to practice scoring than to add original music to a great movie that
                        never had music to begin with! <em>The Birds</em> (by Alfred Hitchcock) is a challenge
                        because the sound design is so good that it doesn't need music in order to be successful;
                        even so, I wanted to see if I could enhance the film by adding a score. This is what I've
                        come up with so far, as a demo for the first 3 cues (piano only).
                      </>
                    )}
                  >
                    <VideoClip
                      label="First 3 scenes"
                      url="compositions/The birds - first 3 scenes - v12.m4v"
                      noDownload
                    />
                    <AudioClip
                      label="First 3 scenes — soundtrack"
                      url="compositions/The birds - first 3 scenes - v12 - soundtrack.m4a"
                      noDownload
                    />
                    <AudioClip
                      label="Music cue 1 — title"
                      url="compositions/The birds - cue 1 title - v12.m4a"
                    />
                    <AudioClip
                      label="Music cue 2 — street scene"
                      url="compositions/The birds - cue 2 street scene - v12.m4a"
                    />
                    <AudioClip
                      label="Music cue 3 — bird store"
                      url="compositions/The birds - cue 3 bird store - v12.m4a"
                    />
                  </MediaExample>
                  <MediaExample
                    title="Kiki's Delivery Service"
                    author="Hayao Miyazaki"
                    composer={rossAdamson}
                    description={(
                      <>
                        a light piano rescoring of a scene from <em>Kiki's Delivery Service</em>,
                        done for my film scoring class at BYU; I had never seen this movie nor
                        heard the original film score at the time when I wrote this music.
                      </>
                    )}
                  >
                    <VideoClip
                      label="Kiki flies with birds"
                      url="compositions/ross-kiki.m4v"
                      noDownload
                    />
                    <AudioClip
                      label="Kiki flies with birds — soundtrack"
                      url="compositions/ross-kiki-all-sound.m4a"
                      noDownload
                    />
                    <AudioClip
                      label="Kiki flies with birds — music — extended version"
                      url="compositions/ross-kiki-music-extended.m4a"
                    />
                  </MediaExample>
                  <MediaExample
                    title="Mother: Tales of Love and Terror"
                    author="Willow Becker"
                    composer={rossAdamson}
                    description={(
                      <>
                        Mother is a horror fiction anthology. I scored the music for this
                        {' '}
                        <a target="_blank" href="https://www.kickstarter.com/projects/wlw/mother-tales-of-love-and-terror-0" rel="noreferrer">Kickstarter</a>
                        {' '}
                        promotional video, using a virtual orchestra and choir. Near the end, I quote the traditional American
                        lullaby "All the Pretty Little Horses" (also known as "Hush-a-bye").
                      </>
                    )}
                  >
                    <VideoClip
                      label="Promotional video"
                      url={[
                        {
                          url: 'https://v2.kickstarter.com/1672942749-N057nvhd7wFcJ3R%2F8%2BWXUMZkgYrmD1wIKfnIIpuo90E%3D/projects/4343623/video-1160389-hls_playlist.m3u8',
                          type: 'application/x-mpegURL',
                          disabled: true,
                        },
                        {
                          url: 'https://v2.kickstarter.com/1672942749-N057nvhd7wFcJ3R%2F8%2BWXUMZkgYrmD1wIKfnIIpuo90E%3D/projects/4343623/video-1160389-h264_high.mp4',
                          type: 'video/mp4; codecs=&quot;avc1.64001E, mp4a.40.2&quot;',
                          disabled: true,
                        },
                        {
                          url: 'https://v2.kickstarter.com/1672942749-N057nvhd7wFcJ3R%2F8%2BWXUMZkgYrmD1wIKfnIIpuo90E%3D/projects/4343623/video-1160389-h264_base.mp4',
                          type: 'video/mp4; codecs=&quot;avc1.42E01E, mp4a.40.2&quot;',
                          disabled: true,
                        },
                        'compositions/mother-promo-h264_high.mp4',
                      ]}
                      noDownload
                    />
                    <AudioClip
                      label="Promotional video — soundtrack"
                      url="compositions/mother-promo-all-sound.m4a"
                      noDownload
                    />
                    <AudioClip
                      label="Promotional video - music — extended version"
                      url="compositions/mother-lullaby-v3.m4a"
                    />
                  </MediaExample>
                  <MediaExample
                    title="Horse Crazy 2: The Legend of Grizzly Mountain"
                    author="Eric Hendershot"
                    composer={rossAdamson}
                    description={(
                      <>
                        my own rescoring of a scene from <em>Horse Crazy 2: The Legend of Grizzly Mountain</em>
                      </>
                    )}
                  >
                    <VideoClip
                      label="Cue 1 - title"
                      url="compositions/thor-short-web.mov"
                      // 4:3 aspect ratio
                      heightOverWidth={0.75}
                      noDownload
                    />
                    <AudioClip
                      label="Cue 1 - title - soundtrack"
                      url="compositions/thor-short-web.m4a"
                      noDownload
                    />
                  </MediaExample>
                  <MediaExample
                    title="Clown Scene"
                    composer={rossAdamson}
                    description={(
                      <>
                        a short orchestral piece which follows an imagined scene of mystery and suspense
                        involving a chase scene down dark alleys and a frightening clown (music only)
                      </>
                    )}
                  >
                    <AudioClip
                      url={{ url: 'compositions/clown clip.mp3', type: 'audio/mpeg' }}
                    />
                  </MediaExample>
                  <MediaExample
                    title="The View from the Ledge"
                    author="Willow Becker"
                    composer={rossAdamson}
                    description={(
                      <>
                        chilling music for a podcast reading of a dark, fictional short story called
                        {' '}
                        <a target="_blank" href="http://weirdlittleworlds.com/view-windowsill/"
                            title="The View from the Ledge (Podcast)" rel="noreferrer">The View from the Ledge</a>
                        {' '}
                        (written and narrated by Willow Becker)
                      </>
                    )}
                  >
                    <AudioClip
                      label="Podcast soundtrack"
                      url={[
                        {
                          url: 'http://willowdawnbecker.com/wp-content/uploads/2014/05/Friday-Flash-View-From-the-Ledge-52814-10.09-AM.mp3?_=1',
                          type: 'audio/mpeg',
                          disabled: true,
                        },
                        {
                          url: 'compositions/Friday-Flash-View-From-the-Ledge-52814-10.09-AM.mp3',
                          type: 'audio/mpeg',
                        },
                      ]}
                      noDownload
                    />
                    <AudioClip
                      label="Music — extended"
                      url={{ url: 'compositions/view-windowsill.mp3', type: 'audio/mpeg' }}
                    />
                  </MediaExample>
                  <MediaExample
                    title="Netflixers Movie Review"
                    composer={rossAdamson}
                    description={(
                      <>
                        I wrote this sound logo for the brand video of a
                        {' '}
                        <a target="_blank" href="https://www.youtube.com/@NetflixersMovieReview" rel="noreferrer">YouTube channel series</a>.
                        {' '}
                        Here are 2 different versions of the music I worked on with the director.
                      </>
                    )}
                  >
                    <VideoClip
                      label="Logo — version 1"
                      url="compositions/Netflixers_Movie_Review_Animated_Logo_FINAL SHORT_demo_1.mov"
                      noDownload
                    />
                    <AudioClip
                      label="Logo — version 1"
                      url="compositions/Netflixers_Movie_Review_Animated_Logo_FINAL SHORT_demo_1.mp3"
                    />
                    <VideoClip
                      label="Logo — version 2"
                      url="compositions/Netflixers_Movie_Review_Animated_Logo_FINAL SHORT_music3.mov"
                      noDownload
                    />
                    <AudioClip
                      label="Logo — version 2"
                      url="compositions/Netflixers_Movie_Review_Animated_Logo_FINAL SHORT_music3.mp3"
                    />
                  </MediaExample>
                  <MediaExample
                    title="Mr Dirty Socks"
                    composer={rossAdamson}
                    description={(
                      <>
                        <a target="_blank" href="https://www.youtube.com/channel/UChgwye6IUGA2HAAc9U_lk-w" rel="noreferrer">Mr Dirty Socks</a>
                        {' '}
                        is a mobile 3D 3rd person shooter game where the main character is a
                        sad, angry, dirty sock on the bedroom floor who is not feeling the love!
                        I was approached by the
                        game maker to write some music for the game.
                      </>
                    )}
                  >
                    <AudioClip
                      label="Main menu demo 1"
                      url={{
                        url: 'compositions/Mr Dirty Socks - Feeling Dirty (Demo 1).mp3',
                        type: 'audio/mpeg',
                      }}
                      noDownload
                    />
                    <AudioClip
                      label="Main menu demo 2"
                      url={{
                        url: 'compositions/Mr Dirty Socks - Feeling Dirty (Demo 2).mp3',
                        type: 'audio/mpeg',
                      }}
                      noDownload
                    />
                    <AudioClip
                      label="Gameplay demo 1"
                      url={{
                        url: 'compositions/Grumpy Sock.mp3',
                        type: 'audio/mpeg',
                      }}
                    />
                    <AudioClip
                      label="Gameplay demo 2"
                      url={{
                        url: 'compositions/Angry Sock.mp3',
                        type: 'audio/mpeg',
                      }}
                    />
                  </MediaExample>
                  <MediaExample
                    title="Soldier Brothers"
                    author={rossAdamson}
                    composer={rossAdamson}
                    description={(
                      <>
                        a militaristic, playful and sheepish moment written
                        for a short film concept (music only)
                      </>
                    )}
                  >
                    <AudioClip
                      url={{
                        url: 'compositions/soldier brothers intro.mp3',
                        type: 'audio/mpeg',
                      }}
                    />
                  </MediaExample>
                  <MediaExample
                    title="Ready the Cannons"
                    author={rossAdamson}
                    composer={rossAdamson}
                    description={(
                      <>
                        a short, swashbuckling call to action (music only)
                      </>
                    )}
                  >
                    <AudioClip
                      url={{ url: 'compositions/ready-the-cannons.mp3', type: 'audio/mpeg' }}
                    />
                  </MediaExample>
                  <MediaExample
                    title="The Hero Returns"
                    author={rossAdamson}
                    composer={rossAdamson}
                    description={(
                      <>
                        a brassy, triumphant transition (music only)
                      </>
                    )}
                  >
                    <AudioClip
                        url={{ url: 'compositions/Heroic perilous brass theme.mp3', type: 'audio/mpeg' }}
                    />
                  </MediaExample>
                  <MediaExample
                    title="Lamb and Tyger"
                    author={rossAdamson}
                    composer={rossAdamson}
                    description={(
                      <>
                        an orchestral setting for two parallel and contrasting poems by William Blake: "The Lamb" and "The Tyger";
                        Music accompanies the textual narration of the poems. I use the same 4 note musical motif
                        in each poem, though the key is major in the first and minor in the second.
                      </>
                    )}
                  >
                    <VideoClip
                      label="Lamb"
                      url="https://www.youtube.com/embed/ZflSHbd4uts"
                      isYouTube
                      // 4:3 aspect ratio
                      heightOverWidth={0.75}
                      start={8}
                      stop={146}
                    />
                    <VideoClip
                      label="Tyger"
                      url="https://www.youtube.com/embed/ZflSHbd4uts"
                      isYouTube
                      // 4:3 aspect ratio
                      heightOverWidth={0.75}
                      start={146}
                    />
                    <AudioClip
                      url="compositions/lamb-and-tyger.mp3"
                    />
                  </MediaExample>
                  <MediaExample
                    title="Earth Our Home"
                    author={rossAdamson}
                    composer={rossAdamson}
                    description={(
                      <>
                        a montage on planetary stewardship for a Biology class project
                      </>
                    )}
                  >
                    <VideoClip
                      url="https://www.youtube.com/embed/SoUUJ7PeEy4"
                      isYouTube
                      // 4:3 aspect ratio
                      heightOverWidth={0.75}
                      start={63}
                    />
                    <AudioClip
                      url="compositions/earth-our-home.mp3"
                    />
                  </MediaExample>
                </MediaSection>

                <MediaSection
                  title="Instrumental and electronic pieces"
                >
                  <MediaExample
                    title="Sparkled Journey"
                    author={rossAdamson}
                    composer={rossAdamson}
                    description={(
                      <>
                        (Mar 2014) for piano
                      </>
                    )}
                    links={[
                      {
                        label: 'Apple Music',
                        href: 'https://music.apple.com/us/album/sparkled-journey-single/1462491503',
                        title: 'Sparkled Journey (Apple Music)',
                      },
                      {
                        label: 'Sheet Music',
                        href: './pdf/sparkled-journey.pdf',
                      },
                    ]}
                  >
                    <AudioClip
                      url={{ url: 'compositions/sparkled-journey.mp3', type: 'audio/mpeg' }}
                      noDownload
                    />
                  </MediaExample>
                  <MediaExample
                    title="Nocturne Pensativo"
                    author={rossAdamson}
                    composer={rossAdamson}
                    description={(
                      <>
                        for piano
                      </>
                    )}
                    links={[
                      {
                        label: 'Apple Music',
                        title: 'Nocturne Pensativo (Apple Music)',
                        href: 'https://music.apple.com/us/album/nocturne-pensativo-single/1464609914',
                      },
                    ]}
                  >
                    <AudioClip
                      url={{ url: 'compositions/nocturne.mp3', type: 'audio/mpeg' }}
                      noDownload
                    />
                  </MediaExample>
                  <MediaExample
                    title="Forest Construct"
                    author={rossAdamson}
                    composer={rossAdamson}
                    description={(
                      <>
                        (Nov 2009) composition for solo Alto Flute and Acoustic Guitar accompaniment
                      </>
                    )}
                    links={[
                      {
                        label: 'Sheet Music',
                        href: './pdf/forest-construct-c.pdf',
                      },
                    ]}
                  >
                    <AudioClip
                      url={{ url: 'compositions/forest-construct.mp3', type: 'audio/mpeg' }}
                    />
                  </MediaExample>
                  <MediaExample
                    title="Biography of a Star"
                    author={rossAdamson}
                    composer={rossAdamson}
                    description={(
                      <>
                        (April 2008) for flute, clarinet, violin, and cello (live performance)
                      </>
                    )}
                    links={[
                      {
                        label: 'Sheet Music',
                        href: './pdf/biography-of-a-star.pdf',
                      },
                    ]}
                  >
                    <AudioClip
                      url={{ url: 'compositions/biography of a star.mp3', type: 'audio/mpeg' }}
                    />
                  </MediaExample>
                  <MediaExample
                    title="Ripples in Time"
                    author={rossAdamson}
                    composer={rossAdamson}
                    description={(
                      <>
                        (June 2008) impressionist piece for trumpet and piano (live performance)
                      </>
                    )}
                    links={[
                      {
                        label: 'Sheet Music',
                        href: './pdf/ripples in time.pdf',
                      },
                    ]}
                    hidden
                  >
                    <AudioClip
                      url={{ url: 'compositions/ripples in time.mp3', type: 'audio/mpeg' }}
                    />
                  </MediaExample>
                  <MediaExample
                    title="Trumpet Beats"
                    author={rossAdamson}
                    composer={rossAdamson}
                    description={(
                      <>
                        synths and trumpet
                      </>
                    )}
                  >
                    <AudioClip
                      url={{ url: 'compositions/trumpet-beats.mp3', type: 'audio/mpeg' }}
                    />
                  </MediaExample>
                  <MediaExample
                    title="Boiler Room Blitz"
                    author={rossAdamson}
                    composer={rossAdamson}
                    description={(
                      <>
                        (Oct 2008) electronic music (source sounds from trumpet harmon mute)
                      </>
                    )}
                  >
                    <AudioClip
                      url={{ url: 'compositions/boiler room blitz.mp3', type: 'audio/mpeg' }}
                    />
                  </MediaExample>
                  <MediaExample
                    title="White Wanderings"
                    author={rossAdamson}
                    composer={rossAdamson}
                    description={(
                      <>
                        (Nov 2008) electronic music (source sounds from synthesizer)
                      </>
                    )}
                  >
                    <AudioClip
                      url={{ url: 'compositions/white wanderings.mp3', type: 'audio/mpeg' }}
                    />
                  </MediaExample>
                  <MediaExample
                    title="Drifting Tears"
                    author={rossAdamson}
                    composer={rossAdamson}
                    description={(
                      <>
                        for piano
                      </>
                    )}
                  >
                    <AudioClip
                      url={{ url: 'compositions/Tears.m4a' }}
                    />
                  </MediaExample>
                  <MediaExample
                    title="Escaping the Darkness"
                    author={rossAdamson}
                    composer={rossAdamson}
                    description={(
                      <>
                        for piano
                      </>
                    )}
                  >
                    <AudioClip
                      url={{ url: 'compositions/Escaping the Darkness.mp3', type: 'audio/mpeg' }}
                    />
                  </MediaExample>
                  <MediaExample
                    title="Cold Winter"
                    author={rossAdamson}
                    composer={rossAdamson}
                    description={(
                      <>
                        for piano
                      </>
                    )}
                  >
                    <AudioClip
                      url={{ url: 'compositions/Cold Winter.mp3', type: 'audio/mpeg' }}
                    />
                  </MediaExample>
                  <MediaExample
                    title="Invention"
                    author={rossAdamson}
                    composer={rossAdamson}
                    description={(
                      <>
                        for piano
                      </>
                    )}
                  >
                    <AudioClip
                      url={{ url: 'compositions/invention-1.mp3', type: 'audio/mpeg' }}
                    />
                  </MediaExample>
                  <MediaExample
                    title="Fugue in F Major"
                    author={rossAdamson}
                    composer={rossAdamson}
                    description={(
                      <>
                        for piano
                      </>
                    )}
                  >
                    <AudioClip
                      url={{ url: 'compositions/fugue-f-major.mp3', type: 'audio/mpeg' }}
                    />
                  </MediaExample>
                  <MediaExample
                    title="Voidian Chamber"
                    author={rossAdamson}
                    composer={rossAdamson}
                    description={(
                      <>
                        (Dec 2008) solo for trumpet and electronics using real time effects (live performance)
                      </>
                    )}
                    hidden
                  >
                    <AudioClip
                      url={{ url: 'compositions/voidian_chamber.mp3', type: 'audio/mpeg' }}
                    />
                  </MediaExample>
                  <MediaExample
                    title="Cool Beats"
                    author={rossAdamson}
                    composer={rossAdamson}
                    description={(
                      <>
                        electro synth
                      </>
                    )}
                    hidden
                  >
                    <AudioClip
                      url={{ url: 'compositions/cool beats.mp3', type: 'audio/mpeg' }}
                    />
                  </MediaExample>
                </MediaSection>

                <MediaSection
                  title="Songs with words"
                >
                  <MediaExample
                    title="Star"
                    author={rossAdamson}
                    composer={rossAdamson}
                    description={(
                      <>
                        (Feb 2008) for piano and vocal
                        <br />
                        This song was inspired by the movie <em>Stardust</em> and is meant to convey what I thought were
                        some of its most most meaningful themes.
                      </>
                    )}
                  >
                    <AudioClip
                      url={{ url: 'compositions/love_me_for_me.mp3', type: 'audio/mpeg' }}
                    />
                  </MediaExample>
                  <MediaExample
                    title="Climb"
                    author={rossAdamson}
                    composer={rossAdamson}
                    description={(
                      <>
                        (early 2008) rock song for vocal lead, guitar, drums, bass and keyboard
                        (live recording by my band Wink Lemonade -- formerly Confrontational Tonight -- in 2015)
                      </>
                    )}
                  >
                    <AudioClip
                      url={{ url: 'compositions/climb.mp3', type: 'audio/mpeg' }}
                    />
                    <AudioClip
                      label="Mellow solo version"
                      url={{ url: 'compositions/climb-with-harmony.mp3', type: 'audio/mpeg' }}
                    />
                  </MediaExample>
                  <MediaExample
                    title="What would I do?"
                    author={rossAdamson}
                    composer={rossAdamson}
                    description={(
                      <>
                        (April 2008) for piano and vocal
                      </>
                    )}
                  >
                    <AudioClip
                      url={{ url: 'compositions/what would I do.mp3', type: 'audio/mpeg' }}
                    />
                  </MediaExample>
                  <MediaExample
                    title="Beautiful"
                    author={rossAdamson}
                    composer={rossAdamson}
                    description={(
                      <>
                        a short ballad with piano accompaniment
                      </>
                    )}
                  >
                    <AudioClip
                      url={{ url: 'compositions/beautiful.mp3', type: 'audio/mpeg' }}
                    />
                  </MediaExample>
                  <MediaExample
                    title="As in Adam"
                    author={rossAdamson}
                    composer={rossAdamson}
                    description={(
                      <>
                        a Christian worship song for piano and voice
                      </>
                    )}
                  >
                    <AudioClip
                      url={{ url: 'compositions/as-in-adam.mp3', type: 'audio/mpeg' }}
                    />
                  </MediaExample>
                  <MediaExample
                    title="Line on Line"
                    composer={rossAdamson}
                    description={(
                      <>
                        This is a demo for a ballad from a musical I was working on
                        (piano accompaniment)
                      </>
                    )}
                  >
                    <AudioClip
                      url={{ url: 'compositions/line-on-line.mp3', type: 'audio/mpeg' }}
                    />
                  </MediaExample>
                  <MediaExample
                    title="Constant Inconsistency"
                    author={rossAdamson}
                    composer={rossAdamson}
                    description={(
                      <>
                        alternative rock song (lyrics not yet recorded)
                      </>
                    )}
                  >
                    <AudioClip
                      url={{ url: 'compositions/Constant Inconsistency.mp3', type: 'audio/mpeg' }}
                    />
                  </MediaExample>
                  <MediaExample
                    title="Fall Back"
                    author={rossAdamson}
                    composer={rossAdamson}
                    description={(
                      <>
                        unfinished rock song (lyrics not yet written)
                      </>
                    )}
                  >
                    <AudioClip
                      url={{ url: 'compositions/fall back.mp3', type: 'audio/mpeg' }}
                    />
                  </MediaExample>
                </MediaSection>

                <MediaSection
                  title="Arrangements, parodies and collaborations"
                >
                  <MediaExample
                    title="What Wondrous Love Is This"
                    description={(
                      <>
                        I wrote this unique arrangement of a Christian folk hymn for men's trio, utilizing a mixture
                        of ancient and modern harmonies.
                      </>
                    )}
                    links={[
                      {
                        label: 'Sheet Music',
                        href: './pdf/what-wondrous-love-is-this-score.pdf',
                      },
                    ]}
                  >
                    <AudioClip
                      label="Men's chorus live performance"
                      url={{ url: 'compositions/What Wondrous Love Is This - Orem SA Ward 2022-04-24.m4a' }}
                    />
                    <AudioClip
                      label="Solo singer live performance"
                      url={{ url: 'compositions/What wondrous love is this solo.m4a' }}
                    />
                  </MediaExample>
                  <MediaExample
                    title="Fireflies"
                    author="Owl City"
                    description={(
                      <>
                        A choir teacher asked me to write this arrangement for 3-part women's chorus,
                        piano, beat box, and bass guitar. In this demo recording, I sing all the vocal parts.
                      </>
                    )}
                  >
                    <AudioClip
                      url={{ url: 'compositions/fireflies owl city (full).mp3', type: 'audio/mpeg' }}
                    />
                  </MediaExample>
                  <MediaExample
                    title="My Book"
                    description={(
                      <>
                        This is a comedic parody combining 2 songs from the musical <em>My Turn on Earth</em>,
                        with lyrical help from other collaborators.
                      </>
                    )}
                  >
                    <AudioClip
                      url={{ url: "compositions/My Book - for Willow's birthday (Ezrie!).mp3", type: 'audio/mpeg' }}
                    />
                  </MediaExample>
                </MediaSection>

                <div
                  style={{
                    position: 'absolute',
                    bottom: '0px',
                    width: '100%',
                  }}
                  className="center"
                >
                  {/* Page hits: <?php echo $hits ?> */}
                </div>
              </div>
            </div>
          </div>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            style={{
              width: '100%',
              height: `${controlBarHeight}px`,
              border: '1px solid gold',
              backgroundColor: 'lightYellow',
              padding: 10,
              boxSizing: 'border-box',
            }}
          >
            {false && (
              <Box
                display="flex"
                flexDirection="column"
                style={{
                  height: '100%',
                  border: '1px solid gray',
                  padding: 2,
                  width: 200,
                  overflow: 'auto',
                }}
              >
                {clips.map((clip, clipIndex) => (
                  <div
                    key={`${clip.example.id}-${clip.label || ''}-${clipIndex}`}
                    style={{
                      cursor: 'pointer',
                      userSelect: 'none',
                    }}
                    onClick={() => {
                      clip.player.play()
                      // TODO: remove?
                      //clip.example.scrollTo()
                    }}
                  >
                    <b>{clipIndex + 1}.</b>
                    {' '}
                    {clip.example.author && (
                      <>
                        {clip.example.author}:
                        {' '}
                      </>
                    )}
                    <em>{clip.example.title}</em>
                    {' '}
                    {clip.label && (
                      <>
                        ({clip.label})
                        {' '}
                      </>
                    )}
                  </div>
                ))}
              </Box>
            )}
            <Box
              display="flex"
              style={{
                marginLeft: 2,
                width: 300,
                maxWidth: '100%',
              }}
            >
              <h2
                className="nowPlayingHeader"
                style={{
                  ...currentClip?.error && {
                    color: 'red',
                  },
                }}
              >
                {currentClip ? (
                  <span>
                    {(
                      (false as boolean)
                      && currentClip.example.author
                      && (currentClip.example.author !== rossAdamson)
                    ) && (
                      <>
                        {currentClip.example.author}:
                        {' '}
                      </>
                    )}
                    <em>{currentClip.example.title}</em>
                    {' '}
                    {currentClip.label && (
                      <>
                        ({currentClip.label})
                        {' '}
                      </>
                    )}
                  </span>
                ) : (
                  <span><b>Playing:</b> none</span>
                )}
              </h2>
            </Box>
            <IconButton
              size="large"
              style={{
                color: previousClip ? 'orange' : 'lightGray',
                paddingLeft: '5px',
                paddingRight: '5px',
              }}
              disabled={!previousClip}
              data-id="play-next-button"
              onClick={() => {
                if (previousClip) {
                  previousClip.player.play()
                  playPauseButtonRef.current?.focus()
                }
              }}
            >
              <SkipPreviousIcon style={{ fontSize: '100%' }} />
            </IconButton>
            <IconButton
              ref={setPlayPauseButton}
              size="large"
              style={{
                color: currentClip ? 'orange' : 'lightGray',
                paddingLeft: '5px',
                paddingRight: '5px',
              }}
              disabled={!currentClip}
              data-id="play-button"
              onClick={() => {
                if (currentClip?.playing) {
                  currentClip.player.pause()
                } else {
                  currentClip?.player.play()
                }
              }}
            >
              {currentClip?.playing ? (
                <PauseIcon style={{ fontSize: '100%' }} />
              ): (
                <PlayArrowIcon style={{ fontSize: '100%' }} />
              )}
            </IconButton>
            <IconButton
              size="large"
              style={{
                color: nextClip ? 'orange' : 'lightGray',
                paddingLeft: '5px',
                paddingRight: '5px',
              }}
              disabled={!nextClip}
              data-id="play-next-button"
              onClick={() => {
                if (nextClip) {
                  nextClip.player.play()
                  playPauseButtonRef.current?.focus()
                }
              }}
            >
              <SkipNextIcon style={{ fontSize: '100%' }} />
            </IconButton>
            {currentClip && (
              <MediaSession
                title={
                  `${
                    currentClip.example.author
                      ? `${currentClip.example.author}: `
                      : ''
                  }${currentClip.example.title}${
                    currentClip.label
                      ? ` (${currentClip.label})`
                      : ''
                  }`
                }
                artist={currentClip.example.composer || currentClip.example.author || undefined}
                album={currentClip.example.title}
                artwork={[
                  /*
                  {
                    src: 'cover_large.jpeg',
                    sizes: '256x256,384x384,512x512',
                    type: 'image/jpeg',
                  },
                  {
                    src: 'cover_small.jpeg',
                    sizes: '96x96,128x128,192x192',
                    type: 'image/jpeg',
                  },
                  */
                ]}
                onPlay={() => {
                  try {
                    currentClip.player.play()
                    playPauseButtonRef.current?.focus()
                  } catch (e) {
                    // do nothing
                  }
                }}
                onPause={() => {
                  try {
                    currentClip.player.pause()
                    playPauseButtonRef.current?.focus()
                  } catch (e) {
                    // do nothing
                  }
                }}
                //onSeekBackward={onSeekBackward}
                //onSeekForward={onSeekForward}
                {...previousClip && {
                  onPreviousTrack: () => {
                    try {
                      previousClip.player.play()
                      playPauseButtonRef.current?.focus()
                    } catch (e) {
                      // do nothing
                    }
                  },
                }}
                {...nextClip && {
                  onNextTrack: () => {
                    try {
                      nextClip.player.play()
                      playPauseButtonRef.current?.focus()
                    } catch (e) {
                      // do nothing
                    }
                  },
                }}
              />
            )}
          </Box>
        </>
      </ScrollViewContext.Provider>
    </PlaylistManagerContext.Provider>
  )
}

export default App
